import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { isFuture } from "date-fns"

/* Import Global Components */
import Page from "~components/page"
import PostPreview from "~components/postPreview"
import BlockContent from "~components/blockContent"

/* Import Local Styles */
import "./highlights.scss"

const HighlightsPage = () => {
  const {
    allSanityHighlights: { nodes: highlights },
  } = useStaticQuery(graphql`
    query HighlightsProviderQuery {
      allSanityHighlights(filter: { title: { eq: "Highlights" } }) {
        nodes {
          _rawIntroduction
          highlights {
            _id
            postAuthor {
              _id
              name
              slug
              publishOnWebsite
            }
            postTitle
            publicationDate
            _rawPostSummary
          }
        }
      }
    }
  `)

  const isPublished = post => {
    return !isFuture(new Date(post.publicationDate))
  }

  const highlight = highlights[0]
  if (!highlight) return null

  const posts = highlight.highlights

  return (
    <Page title="highlights">
      <BlockContent blocks={highlight._rawIntroduction} />
      <hr />
      {posts.filter(isPublished).map(post => (
        <PostPreview key={post._id} post={post} />
      ))}
    </Page>
  )
}

export default HighlightsPage
